import { useContext, useState, useEffect, Fragment, useMemo, useCallback } from 'react'
import { AppContext } from '../../App'
import { DropdownMenu, Frame, IconButton, Input, Modal, PortariaDownloadButtons, Tab, TabList, TabPanel, Table, Tabs } from '../../c'
import DfeModal from '../dfeModal'
import { formatNumber, sendAppApiDeletePortaria } from '../../f'
import NfpModal from '../nfpModal'
import AnexarDcoAuxModal from '../anexarDcoAuxModal'
import { PrintTicketFracionadoModal, PrintTicketModal } from '../printTicketModal'
import AnexarTicketModal from '../anexarTicketModal'
import AnexarCanhotoModal from '../anexarCanhotoModal'

const CARGAS_CHEIAS_INICIAL = []
const CARGAS_FRACIONADAS_INICIAL = []

export default function TransportadorAgendados({ setReloader }) {
  const App = useContext(AppContext)
  const {
    baseURL,
    icons,
    lang: { global, transportador, GerencialOperacao } = {},
  } = App

  const [loading, setLoading] = useState(false)
  const [cargasCheias, setCargasCheias] = useState(CARGAS_CHEIAS_INICIAL)
  const [cargasFracionadas, setCargasFracionadas] = useState(CARGAS_FRACIONADAS_INICIAL)
  const [openTicket, setOpenTicket] = useState(null)
  const [openTicketFracionado, setOpenTicketFracionado] = useState(null)
  const [openDfe, setOpenDfe] = useState(false)
  const [openCTe, setOpenCTe] = useState(false)
  const [openMDFe, setOpenMDFe] = useState(false)
  const [openNfp, setOpenNfp] = useState(false)
  const [openCanhotoModal, setOpenCanhotoModal] = useState(null)
  const [openDcoAuxModal, setOpenDcoAuxModal] = useState(false)
  const [openDescargaModal, setOpenDescargaModal] = useState(null)
  const [showFilters, setShowFilters] = useState(false)
  const [agendarAppa, setAgendarAppa] = useState(null)
  const [cancelAgendado, setCancelAgendado] = useState({})

  const lang = useMemo(
    () => ({ ...global, ...transportador, ...GerencialOperacao }),
    [GerencialOperacao, global, transportador]
  )

  const frameActions = useMemo(() => {
    return {
      filter: {
        onClick: () => setShowFilters(!showFilters),
        value: showFilters
      }
    }
  }, [showFilters])

  const columns = useMemo(() => {
    return [
      ['', 'ACTION', {}, 'force-fit'],
      [lang.operacao, 'DS_OPRPRT', {}, 'force-fit'],
      [lang.ticket, 'ID_UOCC'],
      [lang.veiculo, 'ID_VCL'],
      [lang.modal, "DS_VIATRP"], //VD-10297 Por Hugo em 28/03/2024
      [lang.contrato, 'ID_CN'],
      [lang.produto, 'NM_PRO'],
      [lang.cliente, 'NM_PRC'],
      [lang.tomador, '_TOMADOR'],
      [lang.origem, 'NM_ORI'],
      [lang.destino, 'NM_DST'],
      [lang.motorista, 'NM_MTR'],
      [lang.situacao, 'DS_STS'],
      [lang.previsao, 'DT_PRV'],
      [lang.turno, 'DS_TRN'],
      [<><icons.BsPlugin />Integrações</>, '_INTEGRATIONS', {}, 'force-fit'],
      [<><icons.MdCloudDownload />{lang.downloads}</>, 'downloads', {}, 'f g1'],
    ]
  }, [lang])

  const fetchCargasCheias = useCallback(
    async () => {
      const { status, results } = await App.api('portarias::get')

      const cargasEncontradas = status
        ? results
        : JSON.parse(sessionStorage.getItem('portarias::get'))

      sessionStorage.setItem('portarias::get', JSON.stringify(cargasEncontradas))

      setCargasCheias(cargasEncontradas);
    },
    [App]
  )

  const fetchCargasFracionadas = useCallback(async () => {
    if (App?.cliente?.id === 'cvale') return

    const { results: cargasEncontradas } = await App.api('ordemCarregamento::getPortariasByTrp')

    setCargasFracionadas(cargasEncontradas)
  }, [App])

  const fetchData = useCallback(async () => {
    setLoading(true)

    await fetchCargasCheias()
    await fetchCargasFracionadas()

    setLoading(false)
  }, [fetchCargasCheias, fetchCargasFracionadas])

  const liberarPortaria = useCallback((portaria) => {
    if (portaria.TP_OPRPRT === 'E' || portaria.IN_NFE !== true || !!portaria.IN_NFE_XML) {
      App.confirm(
        lang.deseja_liberar_transito.replace('%d', portaria.ID_VCL),
        async () => {
          const { status } = await App.api('portarias::alteraSituacao', {
            id_uocc: portaria.ID_UOCC,
            cd_sts: '5',
            allows_to_traffic_release: portaria.allows_to_traffic_release
          })

          App.toast.success(lang.liberado_transito)
          fetchData()

          return status
        }
      )
    } else { setOpenDfe(portaria) }
  }, [App, fetchData, lang.deseja_liberar_transito, lang.liberado_transito])

  const aoFecharDfe = useCallback(() => {
    setOpenDfe(false)
  }, [])

  const aoFinalizarDfe = useCallback(async () => {
    const { status } = await App.api(
      'portarias::alteraSituacao',
      {
        id_uocc: openDfe.ID_UOCC, cd_sts: '5', allows_to_traffic_release: openDfe.allows_to_traffic_release

      }
    )

    if (status) fetchData()

    return status
  }, [App, fetchData, openDfe.ID_UOCC, openDfe.allows_to_traffic_release])

  const aoFecharCte = useCallback(() => {
    setOpenCTe(false)
  }, [])

  const aoFinalizarCte = useCallback(async () => {
    fetchData()
  }, [fetchData])

  const aoFecharMdfe = useCallback(() => {
    setOpenMDFe(false)
  }, [])

  const aoFinalizarMdfe = useCallback(async () => {
    fetchData()
  }, [fetchData])

  const dadosCargaCheia = useMemo(
    () => {
      const {
        carga,
        descarga,
        liberar_transito,
        imprimir_ticket,
        anexar_nfe,
        anexar_cte,
        anexar_mdfe,
        anexar_nfp,
        anexar_canhoto,
        anexar_documento_auxiliar
      } = lang

      return cargasCheias.map((cargaCheia) => ({
        ...cargaCheia,
        key: cargaCheia.id || cargaCheia.key || cargaCheia.ID_UOCC,
        _INTEGRATIONS: (!!cargaCheia.ID_APPA && !!cargaCheia.IN_NFE_XML)
          ? (
            !!cargaCheia.IN_APPA_AGENDADO
              ? <icons.FaShip title={'APPA OK'} className='t-success' size={18} />
              : <IconButton title={'APPA Pendente'} onClick={() => setAgendarAppa(cargaCheia)} >
                <icons.FaShip className='t-danger blink circle' />
              </IconButton>
          )
          : <></>,
        ID_CN: cargaCheia.ID_CN ?? '----',
        DS_OPRPRT: cargaCheia.DS_OPRPRT === 'Carga' ? <icons.CargaIcon title={carga} size={24} /> : <icons.DescargaIcon title={descarga} size={24} />,
        ACTION: <DropdownMenu itens={[
          {
            title: liberar_transito,
            icon: <icons.FaRoad />,
            disabled: !cargaCheia.allows_to_traffic_release,
            action: () => liberarPortaria(cargaCheia)
          },
          {
            title: imprimir_ticket,
            icon: <icons.FaPrint />,
            disabled: !cargaCheia.allows_to_print_ticket,
            action: () => setOpenTicket(cargaCheia)
          },
          {
            title: anexar_nfe,
            icon: <icons.MdAttachFile />,
            disabled: !cargaCheia.allow_attach_nfe,
            action: () => setOpenDfe(cargaCheia)
          },
          {
            title: anexar_cte,
            icon: <icons.MdAttachFile />,
            disabled: !cargaCheia.allow_attach_cte,
            action: () => setOpenCTe(cargaCheia)
          },
          {
            title: anexar_mdfe,
            icon: <icons.MdAttachFile />,
            disabled: !cargaCheia.allow_attach_mdfe,
            action: () => setOpenMDFe(cargaCheia)
          },
          {
            title: anexar_nfp,
            icon: <icons.MdAttachFile />,
            disabled: !cargaCheia.allow_attach_nfp,
            action: () => setOpenNfp(cargaCheia)
          },
          {
            title: anexar_canhoto,
            icon: <icons.MdAttachFile />,
            disabled: !cargaCheia.allows_left_handed_fixation,
            action: () => setOpenCanhotoModal(cargaCheia)
          },
          {
            title: anexar_documento_auxiliar,
            icon: <icons.MdAttachFile />,
            disabled: !cargaCheia.allow_attaching_auxiliary_document,
            action: () => setOpenDcoAuxModal(cargaCheia)
          },
          {
            title: "Ticket de Descarga",
            icon: <icons.MdAttachFile />,
            disabled: !cargaCheia.allow_attaching_unloading_ticket,
            action: () => setOpenDescargaModal(cargaCheia)
          },
          App.diretivas.TranspCancelarAgendamento === 'N'
            ? {}
            : {
              title: "Cancelar agendamento",
              icon: <icons.MdCancel />,
              disabled: !cargaCheia.allow_cancel_travel,
              action: () => {
                setCancelAgendado({
                  open: true,
                  id: cargaCheia.ID_UOCC,
                  vcl: cargaCheia.ID_VCL,
                  motivo: '',
                })
              },
            },
        ]}
        >
          <IconButton icon={icons.BsThreeDotsVertical} size={24} />
        </DropdownMenu>,
        nfe_status_icon:
          <IconButton onClick={() => !!cargaCheia.nfe_status && window.open(baseURL + 'download/?k=' + btoa('nfe|' + cargaCheia.ID_UOCC))}>
            {[
              <icons.BsQuestionCircleFill size={16} />,
              <icons.BsCheck2Circle style={{ color: 'var(--success)' }} size={16} />,
              <icons.MdReportProblem style={{ color: 'var(--warning)' }} size={16} />,
              <icons.MdCancel style={{ color: 'var(--cancel)' }} size={16} />
            ][cargaCheia.nfe_status]}
          </IconButton>,
        cte_status_icon:
          <IconButton onClick={() => !!cargaCheia.cte_status && window.open(baseURL + 'download/?k=' + btoa('cte|' + cargaCheia.ID_UOCC))}>
            {[
              <icons.BsQuestionCircleFill size={16} />,
              <icons.BsCheck2Circle style={{ color: 'var(--success)' }} size={16} />,
              <icons.MdReportProblem style={{ color: 'var(--warning)' }} size={16} />,
              <icons.MdCancel style={{ color: 'var(--cancel)' }} size={16} />
            ][cargaCheia.cte_status]}
          </IconButton>,
        ordem_icon:
          <IconButton onClick={() => !!cargaCheia.LST_ORDEM && window.open(baseURL + 'download/?k=' + btoa('ordem|' + cargaCheia.ID_UOCC))}>
            {!cargaCheia.LST_ORDEM ? <icons.BsQuestionCircleFill size={16} /> : <icons.BsCheck2Circle style={{ color: 'var(--success)' }} size={16} />}
          </IconButton>,
        rf_icon:
          <IconButton onClick={() => !!cargaCheia.LST_RF && window.open(baseURL + 'download/?k=' + btoa('rf|' + cargaCheia.ID_UOCC))}>
            {!cargaCheia.LST_RF ? <icons.BsQuestionCircleFill size={16} /> : <icons.BsCheck2Circle style={{ color: 'var(--success)' }} size={16} />}
          </IconButton>,
        ticket_pesagem_icon:
          <IconButton onClick={() => !!cargaCheia.LST_TICKET_PESAGEM && window.open(baseURL + 'download/?k=' + btoa('ticket_pesagem|' + cargaCheia.ID_UOCC))}>
            {!cargaCheia.LST_TICKET_PESAGEM ? <icons.BsQuestionCircleFill size={16} /> : <icons.BsCheck2Circle style={{ color: 'var(--success)' }} size={16} />}
          </IconButton>,
        downloads: <PortariaDownloadButtons portaria={cargaCheia} />,
        _TOMADOR: cargaCheia.NM_TMD,
      }))
    },
    [cargasCheias, lang, App.diretivas.TranspCancelarAgendamento, icons.BsThreeDotsVertical, liberarPortaria, baseURL]
  )

  useEffect(() => {
    fetchData()
  }, [fetchData])

  useEffect(() => {
    if (setReloader) setReloader(() => fetchData)
  }, [fetchData, setReloader])

  return (<>
    <Tabs>
      <TabList>
        <Tab>
          Carga cheia
          <span className='destaque info'>
            {(cargasCheias ?? []).length}
          </span>
        </Tab>
        <Tab>
          Carga fracionada
          <span className='destaque info'>
            {(cargasFracionadas ?? []).length}
          </span>
        </Tab>
      </TabList>

      {/* Cargas cheias */}
      <TabPanel>
        <Frame
          actions={frameActions}
          className='w100'
          loading={loading}
          title={lang.veiculos_agendados}
        >
          <Table
            className='w100'
            columns={columns}
            data={dadosCargaCheia}
            loading={loading}
            showFilters={showFilters}
          />
        </Frame>
      </TabPanel>

      {/* Cargas fracionadas */}
      <TabPanel>
        <Frame title="Cargas fracionadas" className='w100' loading={loading}>
          <Table
            hasSub='_SUB'
            loading={loading}
            className='w100'
            columns={[
              ['', 'ACTION', {}, 'force-fit'],
              [lang.operacao, 'DS_OPRPRT', {}, 'force-fit'],
              [lang.ticket, 'ID_UOCC'],
              [lang.veiculo, 'ID_VCL'],
              [lang.produto_predominante, 'NM_PRO'],
              [lang.motorista, 'NM_MOTORISTA'],
              [lang.situacao, '_DS_STS'],
              [lang.previsao, 'DT_PRV'],
              [lang.turno, 'DS_TRN'],
            ]} data={
              cargasFracionadas.map(carga => {
                let ds_sts = carga.CD_STS === -3 ? 'Cancelado'
                  : carga.CD_STS === 1 ? 'Não agendado'
                    : carga.CD_STS === 2 ? 'Agendado'
                      : carga.CD_STS === 3 ? 'Em coleta'
                        : carga.CD_STS === -1 ? 'Encerrado' : ''

                //VD-9846 - Thales - 12/04/2024
                if (carga.CD_STS > 2 && carga.QT_PORTARIAS > 1 && carga.QT_PORTARIAS > carga.QT_APR_CONCLUIDA) {
                  let qt_atual = 1
                  qt_atual += carga.QT_APR_CONCLUIDA;
                  ds_sts += " (" + qt_atual + "/" + carga.QT_PORTARIAS + ")";
                }

                return {
                  ...carga,
                  _DS_STS: <div className={['destaque fit-content', carga.CD_STS !== '-1' ? 'success' : 'warning'].join(' ')}>{ds_sts}</div>,
                  DS_OPRPRT: carga.TP_OPRPRT === 'E' ? <icons.CargaIcon title={lang.carga} size={24} /> : <icons.DescargaIcon title={lang.descarga} size={24} />,
                  ACTION: <DropdownMenu itens={[
                    { title: lang.imprimir_ticket, icon: <icons.FaPrint />, disabled: false, action: () => setOpenTicketFracionado(carga) },
                  ]}><IconButton icon={icons.BsThreeDotsVertical} size={24} /></DropdownMenu>,
                  NM_PRO: carga.PRODUTO_PREDOMINANTE?.NM_PRO,
                  DT_PRV: App.moment(carga.TURNO_INICIAL?.DT_CRG).format('DD/MM/YYYY'),
                  DS_TRN: carga.TURNO_INICIAL?.DS_TRN,
                  DS_STS: ds_sts,
                  _SUB: <div className='f g1 f-column'>
                    <Table
                      isSub
                      columns={[
                        ['', 'ACTIONS'],
                        ['Portaria', '_ID_UOCC'],
                        ['Produto predominante', 'NM_PRO'],
                        ['Origem', 'NM_UND'],
                        ['Data e turno', 'DS_DATA_TURNO'],
                        ['Situação', '_DS_STS'],
                        [<><icons.MdCloudDownload />{lang.downloads}</>, 'downloads', {}, 'f g1 force-fit'],
                      ]}
                      data={carga.PORTARIAS.map(portaria => {
                        let ds_sts = portaria.CD_STS === '-1' ? lang.encerrado :
                          portaria.CD_STS === '1' ? lang.em_fila :
                            portaria.CD_STS === '2' ? lang.recepcionado :
                              portaria.CD_STS === '3' ? lang.em_operacao :
                                portaria.CD_STS === '4' ? lang.agendado :
                                  portaria.CD_STS === '5' ? lang.transito : ''
                        return {
                          ...portaria,
                          _DS_STS: <div className={['destaque fit-content', carga.CD_STS !== '-1' ? 'success' : 'warning'].join(' ')}>{ds_sts}</div>,
                          ACTIONS: <DropdownMenu
                            itens={[
                              {
                                title: lang.anexar_nfe,
                                icon: <icons.MdAttachFile />,
                                disabled: !!portaria['LST_NFE'],
                                action: () => setOpenDfe(portaria)
                              },
                              {
                                title: lang.anexar_cte,
                                icon: <icons.MdAttachFile />,
                                disabled: false,
                                action: () => setOpenCTe(portaria)
                              },
                              {
                                title: lang.anexar_documento_auxiliar,
                                icon: <icons.MdAttachFile />,
                                disabled: false,
                                action: () => setOpenDcoAuxModal(portaria)
                              },
                            ]}
                          >
                            <IconButton
                              icon={icons.BsThreeDotsVertical}
                              size={24}
                            />
                          </DropdownMenu>,
                          downloads: <PortariaDownloadButtons portaria={portaria} />,
                          _ID_UOCC: formatNumber(portaria.ID_UOCC),
                          DS_DATA_TURNO: App.moment(portaria.DT_PRV).format('DD/MM/YYYY') + ' - ' + portaria.DS_TRN,
                          DS_STS: ds_sts,
                        }
                      })}
                    />

                    <hr />

                    <Table isSub
                      columns={[
                        ['Destino', 'NM_PES'],
                        ['Itens', '_ITENS'],
                      ]}
                      data={
                        carga.DESTINOS.map(destino => ({
                          ...destino,
                          _ITENS: destino.ITENS.reduce((acc, item) => <>{acc}
                            {item.QT_EXP} x {carga.ITENS.find(ci => ci.ID_PRO === item.ID_PRO)?.NM_PRO} {item.ID_UNDMEXP}
                            <br /></>, '')
                        }))
                      }
                    />
                  </div>,
                }
              })
            }
          />
        </Frame>
      </TabPanel>
    </Tabs>

    {!!openDfe && (
      <DfeModal
        mod={55}
        onClose={aoFecharDfe}
        onFinish={aoFinalizarDfe}
        portaria={openDfe}
        tela="agendado"
      />
    )}

    {!!openCTe && (
      <DfeModal
        mod={57}
        onClose={aoFecharCte}
        onFinish={aoFinalizarCte}
        portaria={openCTe}
        tela="agendado"
      />
    )}

    {!!openMDFe && (
      <DfeModal
        mod={58}
        onClose={aoFecharMdfe}
        onFinish={aoFinalizarMdfe}
        portaria={openMDFe}
        tela="agendado"
      />
    )}

    {!!openNfp && (
      <NfpModal
        onClose={() => setOpenNfp(false)}
        onFinish={() => fetchData()}
        portaria={openNfp}
      />
    )}

    {!!openDcoAuxModal && (
      <AnexarDcoAuxModal
        portaria={openDcoAuxModal}
        onClose={() => setOpenDcoAuxModal(false)}
        onFinish={() => fetchData()}
      />
    )}

    {!!openDescargaModal && (
      <AnexarTicketModal
        cota={openDescargaModal}
        onClose={() => {
          setOpenDescargaModal(null);
          fetchData();
        }}
      />
    )}

    {!!openCanhotoModal && (
      <AnexarCanhotoModal
        cota={openCanhotoModal}
        onClose={() => {
          setOpenCanhotoModal(null);
          fetchData();
        }}
      />
    )}

    {!!openTicket && (
      <PrintTicketModal
        idPrt={openTicket.ID_UOCC}
        onClose={() => setOpenTicket(false)}
      />
    )}

    {!!openTicketFracionado && (
      <PrintTicketFracionadoModal
        ocId={openTicketFracionado.ID_UOCC}
        onClose={() => setOpenTicketFracionado(null)}
      />
    )}

    {!!agendarAppa && (
      <AgendarAppaModal
        portaria={agendarAppa}
        onClose={() => setAgendarAppa(null)}
        onFinish={() => fetchData()}
      />
    )}

    {cancelAgendado.open &&
      <Modal title={lang.cancelar_veiculo} onClose={() => setCancelAgendado({})}
        onFinish={async () => {
          const r = await App.api(
            'portarias::alteraSituacao',
            { id_uocc: cancelAgendado.id, cd_sts: -3, ds_mtv: cancelAgendado.motivo }
          )

          if (r.status) {
            setCancelAgendado({})
            fetchData()
            sendAppApiDeletePortaria({ App, prtIdUocc: cancelAgendado.id, motivo: cancelAgendado.motivo })
            return r.status
          }
        }}
        validate={App.diretivas.CancelarAgendamento === '1' || cancelAgendado.motivo.length >= 10}
      >
        {App.diretivas.CancelarAgendamento === '1'
          ? `Cancelar o veículo ${cancelAgendado.vcl} do agendamento ${cancelAgendado.id}?`
          : <Input value={cancelAgendado.motivo} minLength={App.diretivas.CancelarAgendamento === '2' ? 10 : null}
            label={lang.cancelar_veiculo_informe_motivo + cancelAgendado.vcl + lang.cancelar_veiculo_de_ticket + cancelAgendado.id}
            onChange={(e) => setCancelAgendado({ ...cancelAgendado, motivo: e.target.value })}
          />

        }
      </Modal>
    }
  </>)
}

function AgendarAppaModal({ portaria, onClose, onFinish }) {
  const App = useContext(AppContext)

  const [loading, setLoading] = useState(null),
    [disponibilidades, setDisponibilidades] = useState({}),
    [cotaInt, setCotaInt] = useState({}),
    [hora, setHora] = useState(null)

  const get = useCallback(async () => {
    setLoading(true)

    let _cotaInt = (await App.api('IntegracaoAppa::getCotaIntById', {
      id: portaria.ID_APPA
    })).results
    setCotaInt(_cotaInt)

    let _disponibilidades = (await App.api('IntegracaoAppa::disponibilidades', {
      int_id: portaria.ID_APPA
    })).results
    setDisponibilidades(_disponibilidades.find(disp => disp.DATA === _cotaInt.DT_PRV))

    setLoading(null)
  }, [App, portaria?.ID_APPA])

  const submit = useCallback(async () => {
    setLoading(true)

    const status = (await App.api('IntegracaoAppa::preCriarAgendamento', {
      portaria, cotaInt, hora
    })).status

    setLoading(false)
    if (status) { onFinish() }
    return status
  }, [App, cotaInt, hora, onFinish, portaria])

  useEffect(() => {
    get()
  }, [get])

  return (
    <Modal title={'Agendamento APPA - ' + App.moment(cotaInt.DT_PRV).format('DD/MM/YYYY')}
      onClose={onClose} loading={loading} width={400}
      validate={!!hora}
      onFinish={submit}
    >
      Selecione um horário
      <div className='f g1 f-wrap'>
        {disponibilidades?.HORARIOS?.map((horario, id) => <Fragment key={id}>
          <button key={id} disabled={horario.disponivel === false}
            className={hora === horario.hora ? 'info' : ''}
            onClick={() => setHora(horario.hora)}
          >{horario.hora}</button>
        </Fragment>)}
      </div>
    </Modal>
  )
}
