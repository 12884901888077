import { formatNumber } from "../../../../../../f"

const FIELDS = [
  [
    ['invoiceNumber', 'invoice_number', 'number', 'w50', 'never'],
    ['dueDate', 'due_date', 'date', 'w50', 'value-not-null'],
  ],
  [
    ['calculated', 'calculated_amount', 'float', 'w1-3', 'always'],
    ['amount', 'amount', 'float', 'w1-3', 'never'],
    ['discount', 'discount', 'float', 'w1-3', 'never'],
  ]
]

export const buildFields = (data, lang, handleChangeField) => {
  return FIELDS.map((group) => {
    const keys = []

    const fields = group.map(([field, translateField, type, className, disabledCondition]) => {
      const value = data[field]
      const formattedValue = type === 'float'
        ? formatNumber(value, 2)
        : value

      let disabled = false
      if (disabledCondition === 'always') {
        disabled = true
      } else if (disabledCondition === 'value-not-null') {
        if (type === 'date') {
          disabled = !!value
        } else {
          disabled = !isNaN(value)
        }
      }
      keys.push(field)

      return {
        className,
        disabled,
        error: !(value ?? false),
        key: field,
        label: lang[translateField],
        onChange: handleChangeField(field, type),
        type,
        value: formattedValue,
      }
    })

    return [keys.join('-'), fields]
  })
}
