import { t } from "../../../../f"

export const processInvoicesInBatch = async (
  { data, errors },
  { api, lang, onFetchRecords, toast }
) => {
  if (errors.length > 0 || data[0].length !== 4) {
    const message = t(lang.errors, 'invalid_document')
    toast.warning(message)
    return
  }

  const invoices = data.reduce((group, [cte, ticket, situation, invoiceNumber], line) => {
    if ((situation ?? '').toUpperCase() === 'PAGO') {
      group[invoiceNumber] ||= []
      group[invoiceNumber].push({ cte, ticket })
    }

    return group
  }, {})

  if (Object.keys(invoices).length === 0) {
    const message = t(lang.errors, 'invoices_not_fount')
    toast.warning(message)
    return
  }

  const action = 'Controllership::create_invoices_in_batch'
  const { status, results } = await api(action, { invoices })

  if (!status) {
    const message = t(lang.errors, 'no_import_invoices')
    toast.warning(message)
    return
  }

  const { errors: validations = [] } = results
  const { success_invoices: invoices_count = [] } = results

  if (validations.length > 0) {
    toast.warning()
    toast.error(
      <div>
        {t(lang.errors, 'no_import_invoices_details')}
        <ul>
          {validations.map(({ error, ...options }) => {
            const message = t(lang.errors, error, options)
            return <li key={error}>{message}</li>
          })}
        </ul>
      </div >
    )
    if (invoices_count.length > 0) {
      toast.success(t(lang, 'success'))
    }
  }

  toast.success(t(lang, 'success'))
  onFetchRecords()
}
