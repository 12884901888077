import moment from "moment"

export const fillDataFields = (records, App) => {
  const amount = records.reduce(
    (sum, row) => sum + row.amount,
    0
  )

  let calculatedDueDate = '';

  const diasVencimento = Number(App.diretivas.VencimentoRegistroFatura);

  if (!isNaN(diasVencimento) && diasVencimento > 0) {
    calculatedDueDate = moment().add(diasVencimento, 'days').format('YYYY-MM-DD');
  }

  return {
    calculated: amount,
    amount,
    discount: 0,
    dueDate: calculatedDueDate,
    invoiceNumber: '',
    pdf: null,
    images: []
  }
}
