import { useContext, useState, useEffect } from "react";
import { Modal, Input } from "../../../../../c";
import { AppContext } from "App";

export const EditInvoiceModal = ({
  isOpen,
  onClose,
  invoice: initialInvoice,
  onSave,
}) => {
  const [invoice, setInvoice] = useState(initialInvoice);
  const [originalDueDate, setOriginalDueDate] = useState(initialInvoice.DT_VCT);
  const App = useContext(AppContext);
  const lang = { ...App.lang.global, ...App.lang.freight_controllership.invoicing_modal };

  useEffect(() => {
    setOriginalDueDate(initialInvoice.DT_VCT);
  }, [initialInvoice]);

  const handleSave = () => {
    onSave(invoice, originalDueDate);
    onClose();
  };

  const handleInputChange = (name) => {
    return (event) => {
      setInvoice((prevState) => ({
        ...prevState,
        [name]: event.target.value
      }));
    };
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} onFinish={handleSave} title={lang.edit_due_date}>
      <Input
        id="due-date"
        type="date"
        value={invoice.DT_VCT}
        onChange={handleInputChange('DT_VCT')}
      />
    </Modal>
  );
};
